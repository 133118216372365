<template>
  <div class='asset-allocation'>
    <tab-table
      :columns="columns"
      :findApi="findAssetAllocation"
      :exportApi="exportAssetAllocation"
    />
  </div>
</template>

<script>
import tabTable from "../tab-table";
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { findAssetAllocation, exportAssetAllocation } from '../apis'
import { assetAllocationData } from '../datas'
import {} from '../methods'

export default defineComponent({
  name: "asset-allocation",
  components:{
    tabTable,
  },
  setup(){
    let data = reactive(assetAllocationData)
    onMounted(() => {
        
    })
    
    return{
      ...toRefs(data),
      findAssetAllocation,
      exportAssetAllocation,
    }
  }
})
</script>

<style scoped lang="less">
.asset-allocation {
  margin-top:60px;
}
</style>